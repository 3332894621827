import { CheckCircleIcon } from "@heroicons/react/24/solid";
import getConfig from "next/config";
import Image from "next/image";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export function SetRulesAndLimits() {
  return (
    <>
      <div className="static w-full">
        <div className="relative">
          <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
          <div className="m-[2px]"></div>
          <div className="relative z-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-brown-500 from-0% to-brown-700 to-70% xl:w-[600px] xl:h-[400px] rounded-xl">
            <div className="flex flex-col px-8 pt-4 pb-2 text-sm text-center text-white xl:pt-10 xl:pb-8 xl:text-2xl">
              Set Spending Limits
            </div>
            <div className="flex flex-row items-center justify-center px-8 py-1 xl:py-2 text-center text-white text-[8px] xl:text-base">
              <div className="w-1/2 mr-2 text-left xl:1/3">
                Per Transaction Limit
              </div>
              <div className="flex items-center w-1/2 h-5 pl-4 text-left bg-white rounded-sm xl:rounded-md xl:2/3 xl:h-10 text-brown-500">
                <span className="animate-fade">$500.00</span>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center px-8 py-1 xl:py-2 text-center text-white text-[8px] xl:text-base">
              <div className="w-1/2 mr-2 text-left xl:1/3">
                Daily Spend Limit
              </div>
              <div className="flex items-center w-1/2 h-5 pl-4 text-left bg-white rounded-sm xl:rounded-md xl:2/3 xl:h-10 text-brown-500">
                <span className="animate-fade">$1,000.00</span>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center px-8 py-1 xl:py-2 text-center text-white text-[8px] xl:text-base">
              <div className="w-1/2 mr-2 text-left xl:1/3">
                Weekly Spend Limit
              </div>
              <div className="flex items-center w-1/2 h-5 pl-4 text-left bg-white rounded-sm xl:rounded-mdlg:2/3 xl:h-10 text-brown-500">
                <span className="animate-fade">$6,000.00</span>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center px-8 pt-1 pb-4 xl:pt-2 xl:pb-0 text-center text-white text-[8px] xl:text-base">
              <div className="w-1/2 mr-2 text-left xl:1/3">
                Monthly Spend Limit
              </div>
              <div className="flex items-center w-1/2 h-5 pl-4 text-left bg-white rounded-sm xl:rounded-md xl:2/3 xl:h-10 text-brown-500">
                <span className="animate-fade">$20,000.00</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function AssignAndCloselyTrack() {
  return (
    <>
      <div className="static w-full">
        <div className="relative">
          <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
          <div className="m-[2px]"></div>
          <div className="relative z-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-brown-500 from-0% to-brown-700 to-70% xl:w-[600px] xl:h-[400px] rounded-xl">
            <div className="flex flex-col px-8 pt-8 text-sm text-white xl:text-2xl">
              All Cards
            </div>
            <div className="flex flex-row justify-between px-8 pt-2 pb-2 xl:pt-4 xl:mb-1 text-[8px] xl:text-base text-white">
              <div className="flex flex-col">
                Cards in use
                <div className="font-semibold">56/64</div>
              </div>
              <div>
                <div className="px-2 text-[8px] xl:text-sm text-center bg-transparent border-2 border-white rounded-md animate-pulse">
                  Assign Driver
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center px-8 text-[8px] xl:text-base text-center text-white">
              <div className="w-1/3 mr-2 text-left xl:w-1/4">Card Number</div>
              <div className="w-1/3 mr-2 text-left xl:w-1/4">Assigned To</div>
              <div className="hidden w-1/3 mr-2 text-left xl:w-1/4 xl:block"></div>
              <div className="w-1/3 mr-2 text-left xl:w-1/4"></div>
            </div>
            <div className="h-px mx-6 border-0 xl:my-2 bg-brown-300"></div>
            <div className="flex flex-row items-center justify-center px-8 py-1 xl:py-2 xl:my-2 text-[8px] xl:text-base text-center text-white ">
              <div className="w-1/3 mr-2 text-left xl:w-1/4">****1092</div>
              <div className="w-1/3 mr-2 text-left xl:w-1/4 animate-bounce">
                Jonathan N.
              </div>
              <div className="hidden mx-2 text-left xl:block xl:w-1/4">
                <div className="hidden text-center border-2 border-white rounded-md xl:block">
                  Spend Limits
                </div>
              </div>
              <div className="w-1/3 mx-2 mr-2 text-left xl:w-1/4">
                <div className="text-center bg-red-700 border-2 border-red-700 xl:rounded-md">
                  Lock Card
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center px-8 py-1 xl:py-2 xl:my-2 text-[8px] xl:text-base text-center text-white ">
              <div className="w-1/3 mr-2 text-left xl:w-1/4">****3002</div>
              <div className="w-1/3 mr-2 text-left xl:w-1/4 animate-bounce">
                Craig J.
              </div>
              <div className="hidden mx-2 text-left xl:block xl:w-1/4">
                <div className="text-center border-2 border-white rounded-md">
                  Spend Limits
                </div>
              </div>
              <div className="w-1/3 mx-2 mr-2 text-left xl:w-1/4">
                <div className="text-center bg-red-700 border-2 border-red-700 xl:rounded-md">
                  Lock Card
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center px-8 pt-1 pb-4 xl:py-2 xl:my-2 text-[8px] xl:text-base text-center text-white ">
              <div className="w-1/3 mr-2 text-left xl:w-1/4">****3098</div>
              <div className="w-1/3 mr-2 text-left xl:w-1/4 animate-bounce">
                Liza P.
              </div>
              <div className="hidden mx-2 text-left xl:block xl:w-1/4">
                <div className="text-center border-2 border-white rounded-md">
                  Spend Limits
                </div>
              </div>
              <div className="w-1/3 mx-2 mr-2 text-left xl:w-1/4">
                <div className="text-center bg-green-700 border-2 border-green-700 xl:rounded-md">
                  Unlock Card
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function AutomatePaymentApprovals() {
  return (
    <>
      <div className="static w-full">
        <div className="relative">
          <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
          <div className="m-[2px]"></div>
          <div className="relative z-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-brown-500 from-0% to-brown-700 to-70%  xl:w-[600px] xl:h-[400px] rounded-xl">
            <div className="flex flex-col px-8 pt-2 text-sm text-white xl:pt-10 xl:pb-8 xl:text-2xl">
              Spend Controls
            </div>
            <div className="flex flex-row items-center py-1 xl:py-2 mx-8 text-[8px] text-center text-white xl:text-sm">
              <div className="flex items-center w-full h-5 py-3 pl-4 text-left bg-white rounded-sm xl:py-4 xl:rounded-md xl:h-10 text-brown-500">
                <CheckCircleIcon className="mr-3 text-green-500 w-5 h-5 xl:w-7 xl:h-7 min-w-[20px] min-h-[20px] xl:min-w-[30px] xl:min-h-[30px] stroke-[3px]" />
                Active: Spend Limits per Card
              </div>
            </div>
            <div className="flex flex-row items-center py-1 xl:py-2 mx-8 text-[8px] text-center text-white xl:text-sm">
              <div className="flex items-center w-full h-5 py-3 pl-4 text-left bg-white rounded-sm xl:py-4 xl:rounded-md xl:h-10 text-brown-500">
                <CheckCircleIcon className="mr-3 text-green-500 w-5 h-5 xl:w-7 xl:h-7 min-w-[20px] min-h-[20px] xl:min-w-[30px] xl:min-h-[30px] stroke-[3px]" />
                Merchant Types Allowed: fuel stations, truck stops, maintenance
              </div>
            </div>
            <div className="flex flex-row items-center py-1 xl:py-2 mx-8 text-[8px] text-center text-white xl:text-sm  animate-translate_up">
              <div className="flex items-center w-full h-5 py-3 pl-4 text-left bg-white rounded-sm xl:py-4 xl:rounded-md xl:h-10 text-brown-500">
                <CheckCircleIcon className="mr-3 text-green-500 w-5 h-5 xl:w-7 xl:h-7 min-w-[20px] min-h-[20px] xl:min-w-[30px] xl:min-h-[30px] stroke-[3px]" />
                Active: SMS Card Unlock and Telematics-based Security
              </div>
            </div>
            <div className="flex flex-row items-center pt-1 pb-2 xl:py-2 mx-8 text-[8px] text-center text-white xl:text-sm animate-translate_up_slow">
              <div className="flex items-center w-full h-5 py-3 pl-4 text-left bg-white rounded-sm xl:py-4 xl:rounded-md xl:h-10 text-brown-500">
                <CheckCircleIcon className="mr-3 text-green-500 w-5 h-5 xl:w-7 xl:h-7 min-w-[20px] min-h-[20px] xl:min-w-[30px] xl:min-h-[30px] stroke-[3px]" />
                Active: Auto-reload Payment
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function FullVisibilityEveryDollar() {
  return (
    <>
      <div className="static w-full">
        <div className="relative">
          <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
          <div className="m-[2px]"></div>
          <div className="relative z-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-brown-500 from-0% to-brown-700 to-70% xl:w-[600px] xl:h-[400px] rounded-xl">
            <div className="flex flex-col px-8 pt-8 text-sm text-white xl:text-2xl">
              Transactions
            </div>
            <div className="flex flex-row items-center justify-center px-8 py-2 text-center text-white text-[8px] xl:text-xs">
              <div className="w-1/6 mr-2 text-left"></div>
              <div className="w-1/6 mr-2 text-left"></div>
              <div className="hidden w-1/6 mr-2 text-left xl:block">
                Merchant
              </div>
              <div className="w-1/6 mr-2 text-left">Cardholder</div>
              <div className="w-1/6 mr-2 text-left">Category</div>
              <div className="w-1/6 mr-2 text-left">Status</div>
            </div>
            <div className="h-px mx-6 mb-1 border-0 md:my-2 bg-brown-300"></div>
            <div className="flex flex-row items-center justify-center px-8 xl:py-2 my-2 text-center text-white text-[6px] xl:text-xs">
              <div className="w-1/6 mr-2 text-left">March 23</div>
              <div className="w-1/6 mr-2 text-left">$245.13</div>
              <div className="hidden w-1/6 mx-2 text-left xl:block">
                Pilot Flying J
              </div>
              <div className="w-1/6 mx-2 text-left">Benjamin P.</div>
              <div className="w-1/6 mx-2 text-left">
                <div className="text-center border-2 xl:rounded-md bg-brown-700 border-brown-700">
                  Fuel
                </div>
              </div>
              <div className="w-1/6 mx-2 mr-2 text-left">
                <div className="text-center bg-green-700 border-2 border-green-700 xl:rounded-md animate-fade">
                  Approved
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center px-8 xl:py-2 my-2 text-[6px] xl:text-xs text-center text-white ">
              <div className="w-1/6 mr-2 text-left">March 23</div>
              <div className="w-1/6 mr-2 text-left">$42.22</div>
              <div className="hidden w-1/6 mx-2 text-left xl:block">
                7 Eleven
              </div>
              <div className="w-1/6 mx-2 text-left">Natalie G.</div>
              <div className="w-1/6 mx-2 text-left">
                <div className="text-center border-2 xl:rounded-md bg-brown-900 border-brown-900">
                  Non-Fuel
                </div>
              </div>
              <div className="w-1/6 mx-2 mr-2 text-left">
                <div className="text-center bg-green-700 border-2 border-green-700 xl:rounded-md animate-fade">
                  Approved
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center px-8 xl:py-2 my-2 text-[6px] xl:text-xs text-center text-white">
              <div className="w-1/6 mr-2 text-left">March 24</div>
              <div className="w-1/6 mr-2 text-left">$98.13</div>
              <div className="hidden w-1/6 mx-2 text-left xl:block">Shell</div>
              <div className="w-1/6 mx-2 text-left">Cameron J.</div>
              <div className="w-1/6 mx-2 text-left">
                <div className="text-center border-2 xl:rounded-md bg-brown-700 border-brown-700">
                  Fuel
                </div>
              </div>
              <div className="w-1/6 mx-2 mr-2 text-left">
                <div className="text-center bg-red-700 border-2 border-red-700 xl:rounded-md animate-fade">
                  2 Issue(s)
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center px-8 pb-4 xl:py-2 my-2 text-[6px] xl:text-xs text-center text-white">
              <div className="w-1/6 mr-2 text-left">March 25</div>
              <div className="w-1/6 mr-2 text-left">$222.13</div>
              <div className="hidden w-1/6 mx-2 text-left xl:block">
                TA Travel Center
              </div>
              <div className="w-1/6 mx-2 text-left">Andy S.</div>
              <div className="w-1/6 mx-2 text-left">
                <div className="text-center border-2 xl:rounded-md bg-brown-700 border-brown-700">
                  Fuel
                </div>
              </div>
              <div className="w-1/6 mx-2 mr-2 text-left">
                <div className="text-center bg-red-700 border-2 border-red-700 xl:rounded-md animate-fade">
                  1 Issue(s)
                </div>
              </div>
            </div>
            <div className="flex-row items-center justify-center px-8 xl:py-2 my-2 text-[6px] xl:text-xs text-center text-white hidden xl:flex">
              <div className="w-1/6 mr-2 text-left">March 26</div>
              <div className="w-1/6 mr-2 text-left">$79.88</div>
              <div className="hidden w-1/6 mx-2 text-left xl:block">Exxon</div>
              <div className="w-1/6 mx-2 text-left">Michael M.</div>
              <div className="w-1/6 mx-2 text-left">
                <div className="text-center border-2 xl:rounded-md bg-brown-700 border-brown-700">
                  Fuel
                </div>
              </div>
              <div className="w-1/6 mx-2 mr-2 text-left">
                <div className="text-center bg-green-700 border-2 border-green-700 xl:rounded-md animate-fade">
                  Approved
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function DigitalReceipts() {
  return (
    <>
      <div className="static w-full">
        <div className="relative">
          <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
          <div className="m-[2px]"></div>
          <div className="relative z-10 p-8 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-brown-500 from-0% to-brown-700 to-70% xl:w-[600px] xl:h-[400px] rounded-xl">
            <div className="flex flex-col w-full">
              <div className="w-9/12 px-3 py-2 text-[8px] text-black bg-gray-100 rounded-md xl:text-sm xl:w-8/12">
                RoadFlex: Purchased $77.95 at Lowe&apos;s with card ending in
                ****9021.
                <div className="mt-2">
                  Please respond with a photo of your receipt.
                </div>
              </div>

              <div className="flex justify-end w-full mt-4 text-[8px] xl:text-sm animate-opacity">
                <div className="w-9/12 px-3 py-2 xl:w-6/12">
                  <div className="max-w-[60px] items-end">
                    <Image
                      className="absolute object-cover opacity-100"
                      src={`${NEXT_PUBLIC_CDN_URL}/controls/images/receipt-only.png`}
                      alt="receipt only"
                      layout="responsive"
                      width={"40"}
                      height={"70"}
                    />
                  </div>
                  <div className="p-2 mt-2 text-[8px] text-white bg-green-600 rounded-md xl:text-sm">
                    Home Improvement Supplies
                  </div>
                </div>
              </div>
              <div className="w-9/12 px-3 py-2 mt-4 text-[8px] text-black bg-gray-100 rounded-md xl:w-6/12 animate-opacity_slow xl:text-sm">
                Receipt successfully uploaded. Do you want to include a memo?
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function CloseBooks() {
  return (
    <>
      <div className="static w-full">
        <div className="relative">
          <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
          <div className="m-[2px]"></div>
          <div className="relative z-10 p-8 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-brown-500 from-0% to-brown-700 to-70% xl:w-[600px] xl:h-[400px] rounded-xl">
            <div className="flex flex-col items-center w-full xl:flex-row">
              <div className="flex flex-col xl:mr-2">
                <div className="w-[100px] h-[40px] xl:w-[250px] xl:h-[100px] animate-opacity mb-5 xl:mb-20">
                  <Image
                    className="absolute object-cover opacity-100"
                    src={`${NEXT_PUBLIC_CDN_URL}/controls/images/logo-sage.png`}
                    alt="xero"
                    layout="responsive"
                    width={"80"}
                    height={"40"}
                  />
                </div>
                <div className="w-[100px] h-[40px] xl:w-[250px] xl:h-[100px] animate-opacity xl:animate-opacity_slow">
                  <Image
                    className="absolute object-cover opacity-100"
                    src={`${NEXT_PUBLIC_CDN_URL}/controls/images/logo-oracle.png`}
                    alt="oracle"
                    layout="responsive"
                    width={"80"}
                    height={"40"}
                  />
                </div>
              </div>
              <div className="flex flex-col mt-5 xl:mt-0">
                <div className="w-[100px] h-[40px] xl:w-[250px] xl:h-[100px] animate-opacity_slow mb-5 xl:mb-20">
                  <Image
                    className="absolute object-cover opacity-100"
                    src={`${NEXT_PUBLIC_CDN_URL}/controls/images/logo-quickbooks.png`}
                    alt="quickbooks"
                    layout="responsive"
                    width={"80"}
                    height={"40"}
                  />
                </div>
                <div className="w-[100px] h-[40px] xl:w-[250px] xl:h-[100px] animate-opacity_slow xl:animate-opacity">
                  <Image
                    className="absolute object-cover opacity-100"
                    src={`${NEXT_PUBLIC_CDN_URL}/controls/images/logo-xero.png`}
                    alt="xero"
                    layout="responsive"
                    width={"80"}
                    height={"40"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
